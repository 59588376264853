<template>
  <div class="sovWrap">
    <div class="container">
      <div class="title_block">
        <p>品牌榜单
          <span class="dataTime">
              统计时间：{{
              time
            }}
            </span></p>
        <div class="flex">
          <el-radio-group text-color="#fff" v-model="siteType"  size="mini">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button
                v-for="(item,index) in sites"
                :key="index"
                :label="item.value"
            >{{ item.label }}
            </el-radio-button>
          </el-radio-group>
          <el-radio-group
              style="margin-left: 40px"
              text-color="#fff"
              v-model="tabType"
              size="mini"
          >
            <el-radio-button label="all">全部</el-radio-button>
            <el-radio-button
                v-for="item in categoryList"
                :key="item.label"
                :label="item.value"
            >{{ item.label }}
            </el-radio-button>
          </el-radio-group>
        </div>

      </div>
      <div class="search_block">
        <div>
          <svg-icon
              icon-class="compareIcon"
              class-name="compareIcon"
          ></svg-icon>
          <el-button
              class="noborderBtn"
              type="text"
              style="margin-right:0;color:#000;"
              @click="brandCompare"
          >
            对比
          </el-button>
        </div>
        <div>
          <svg-icon
              icon-class="downloadIcon"
              class-name="downloadIcon"
          ></svg-icon>
          <el-button
              class="noborderBtn"
              type="text"
              style="margin-right:0;color:#000;"
              @click="downLoadbrand"
          >
            下载
          </el-button>
        </div>
        <span class="line"></span>
        <div class="date_block">
          <span style="margin-right: 10px">时间范围</span>
          <el-date-picker
              v-model="duration"
              type="monthrange"
              unlink-panels
              style="width:180px;"
              :clearable="false"
              range-separator="至"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
              value-format="YYYY-MM"
              :disabled-date="disabledDate"
          >
          </el-date-picker>
        </div>
        <span class="line"></span>
        <el-select
            filterable
            collapse-tags
            multiple
            class="autoWidth"
            v-model="selBrand"
        >
          <template #prefix>
            {{ selBrand.length < 1?'选择品牌':setValue() }}
          </template>
          <el-option
              v-for="(item, index) in chooseBrandList"
              :key="index"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
        <span class="line"></span>
        <div>
          <svg-icon
              icon-class="createNewIcon"
              class-name="createNewIcon"
          ></svg-icon>
          <el-button
              class="noborderBtn"
              type="text"
              style="margin-right:0;color:#000;"
              @click="newBrand"
          >
            新增品牌
          </el-button>
        </div>
      </div>
    </div>
<!--    <div class="container">-->
<!--      <div class="sovTitle">-->
<!--        <div class="filter">-->
<!--          <div style="width:100%;display: flex;align-items: center;justify-content: space-between">-->
<!--            <div style="display: flex;align-items: center;justify-content: flex-start">-->
<!--              <div>-->
<!--                <span style="margin-right: 10px">时间范围</span>-->
<!--                &lt;!&ndash; <el-cascader-->
<!--                  size="mini"-->
<!--                  :show-all-levels="false"-->
<!--                  v-model="duration"-->
<!--                  :options="options"-->
<!--                  @change="handleChange"-->
<!--                ></el-cascader> &ndash;&gt;-->
<!--                <el-date-picker-->
<!--                    v-model="duration"-->
<!--                    type="monthrange"-->
<!--                    unlink-panels-->
<!--                    :clearable="false"-->
<!--                    range-separator="至"-->
<!--                    start-placeholder="开始月份"-->
<!--                    end-placeholder="结束月份"-->
<!--                    value-format="YYYY-MM"-->
<!--                    :disabled-date="disabledDate"-->
<!--                    size="small"-->
<!--                >-->
<!--                </el-date-picker>-->
<!--              </div>-->
<!--              <el-radio-group-->
<!--                  style="margin-left: 40px"-->
<!--                  text-color="#fff"-->
<!--                  v-model="tabType"-->
<!--                  size="mini"-->
<!--              >-->
<!--                <el-radio-button label="all">全部</el-radio-button>-->
<!--                <el-radio-button-->
<!--                    v-for="item in categoryList"-->
<!--                    :key="item.label"-->
<!--                    :label="item.value"-->
<!--                >{{ item.label }}-->
<!--                </el-radio-button>-->
<!--              </el-radio-group>-->
<!--            </div>-->
<!--            <el-button size="mini" @click="newBrand" class="newBrand"-->
<!--            >新增品牌<i class="el-icon-plus iClass"></i-->
<!--            ></el-button>-->
<!--          </div>-->


<!--        </div>-->
<!--        <div class="filter" >-->
<!--          <div>-->
<!--            <span style="margin-right: 10px">选择品牌*</span>-->
<!--            <el-select-->
<!--                size="mini"-->
<!--                style="width: 260px"-->
<!--                filterable-->
<!--                collapse-tags-->
<!--                multiple-->
<!--                placeholder="请选择"-->
<!--                v-model="selBrand"-->
<!--            >-->
<!--              <el-option-->
<!--                  v-for="item in chooseBrandList"-->
<!--                  :key="item.label"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value"-->
<!--              >-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </div>-->
<!--          <el-radio-group-->
<!--              style="margin-left: 40px"-->
<!--              text-color="#fff"-->
<!--              v-model="siteType"-->
<!--              size="mini"-->
<!--          >-->
<!--            <el-radio-button label="">全部</el-radio-button>-->
<!--            <el-radio-button-->
<!--                v-for="item in sites"-->
<!--                :key="item.value"-->
<!--                :label="item.value"-->
<!--            >{{ item.label }}-->
<!--            </el-radio-button>-->
<!--          </el-radio-group>-->
<!--        </div>-->


<!--      </div>-->
<!--    </div>-->
    <div class="table">
<!--      <div class="tab">-->
<!--        <div>-->
<!--          <el-button-->
<!--            style="margin-right: 10px"-->
<!--            size="mini"-->
<!--            @click="brandCompare"-->
<!--            class="spanClass"-->
<!--          >-->
<!--            对比-->
<!--          </el-button>-->
<!--          <el-button size="mini" @click="downLoadbrand" class="spanClass">-->
<!--            下载-->
<!--          </el-button>-->
<!--        </div>-->

<!--        <span>统计时间：{{ time }}</span>-->
<!--        <div class="abClass">-->
<!--          <span style="line-height: 28px">选择</span>-->
<!--        </div>-->
<!--      </div>-->
      <div class="tableMain">
        <el-table
          :height="tableHeight"
          ref="multipleTable"
          v-loading="brandListTableLoading"
          :data="tableData"
          @sort-change="sortChange"
          @select="selectFuncHandle"
          :default-sort="{ prop: 'exposure', order: 'descending' }"
          :expand-row-keys="expandFunc"
          row-key="id"
          :header-cell-style="getRowClass"
        >
          <el-table-column
            type="selection"
            :selectable="disabledSelect"
            width="80"
          >
          </el-table-column>
          <el-table-column prop="number" align="center" label="排名" width="75">
          </el-table-column>
          <el-table-column prop="brand" label="品牌" align="center">
            <template #default="{ row }">
<!--              <span-->
<!--                @click="toBrand(row)"-->
<!--                style="cursor: pointer; color: rgb(81, 166, 240)"-->
<!--                >{{ row.brand }}</span-->
<!--              >-->
              <router-link  @click="toBrand(row)" style="cursor: pointer; color: rgb(81, 166, 240)" :to="{path:'sovBrand',query:{brand:row.brand,brandId:btoa(row.id),duration:JSON.stringify(duration)}}">{{ row.brand }}</router-link>
            </template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="exposure"
            label="曝光量"
            align="center"
          >
            <template #header>
              <span style="cursor: pointer">曝光量</span>
            </template>
            <template #default="scope">
              {{ sliceDecimal(scope.row.exposure) }}
            </template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="engagement"
            label="曝光量"
            align="center"
          >
            <template #header>
              <span style="cursor: pointer">互动量</span>
            </template>
            <template #default="scope">
              {{ sliceDecimal(scope.row.engagement) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="pgcPostNum"
            label="PGC发帖数量"
            align="center"
            width="180"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template #default="scope">
              {{ sliceDecimal(scope.row.pgcPostNum) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="cost"
            label="投放费用(¥)"
            align="center"
            width="180"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template #default="scope">
              {{
                scope.row.cost === null
                  ? "--"
                  : "¥ " + sliceDecimal(scope.row.cost)
              }}
            </template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="cpm"
            label="CPM"
            align="center"
          >
            <template #default="scope">
              {{
                scope.row.cpm === "--"
                  ? "--"
                  : "¥ " + sliceDecimal(scope.row.cpm)
              }}
            </template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="cpe"
            label="CPE"
            align="center"
          >
            <template #default="scope">
              {{
                scope.row.cpe === "--"
                  ? "--"
                  : "¥ " + sliceDecimal(scope.row.cpe)
              }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          @current-change="nextPage"
          :current-page="currentPage"
          layout="prev, pager, next"
          :page-size="size"
          :total="dataTotal"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog title="提示" v-model="dialogVisible" width="30%">
      <p>
        如果您正在关注的品牌暂时不在榜单内，请联系邮件地址：omnivoice@meritco-group.com以添加您需要新增的品牌
      </p>
      <template #footer>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  getBrandList,
  getBrand,
  getCategory,
  getBrandMonth,
  downLoadSovBrandRankingListReport,
  getSovProductAggMonth,
} from "@/api/sov/sovBrand";
import { getSovBrandAggMonth } from "@/api/sov/sovMain";
import { mapState, mapMutations } from "vuex";
import storage from "@/utils/storage";

export default {
  data() {
    return {
      // 分页数据
      currentPage: 1,
      size: 20,
      dataTotal: 0,
      // 头部数据
      duration: "",
      selBrand: [],
      inputBrand: "",
      // 可选品牌list
      chooseBrandList: [],
      options: [
        {
          value: "zhiding",
          label: "指定月",
          children: [],
        },
        {
          value: "1mon",
          label: "近1个月",
        },
        {
          value: "3mon",
          label: "近3个月",
        },
        {
          value: "6mon",
          label: "近6个月",
        },
        {
          value: "12mon",
          label: "近12个月",
        },
        {
          value: "thisYear",
          label: "今年至今",
        },
        {
          value: "lastYear",
          label: "去年全年",
        },
      ],
      // 活动时间
      durationList: [
        {
          value: "1mon",
          label: "近1个月",
        },
        {
          value: "3mon",
          label: "近3个月",
        },
        {
          value: "6mon",
          label: "近6个月",
        },
        {
          value: "12mon",
          label: "近12个月",
        },
        {
          value: "thisYear",
          label: "今年至今",
        },
        {
          value: "lastYear",
          label: "去年全年",
        },
      ],
      brandListTableLoading: true,
      getRowClass: {
        backgroundColor: "#FAFAFA",
        overflow: "hidden",
      },
      categoryList: [],
      tabType: "all",
      tableData: [],
      expandAll: false,
      isExpand: false,
      brandList: [],
      brandNameList: [],
      brandListId: [],
      dialogVisible: false,
      sortColumn: "exposure",
      sortDirection: "desc",
      isExposurSort: 2,
      isSgSort: 0,
      isSrSort: 0,
      time: "",
      // 临时记录 统计时间
      tempTime: "",
      // 测试变量
      timeRange: "",
      timeRangeArr:[],
      siteType:''
      // tableHeight: 300,
    };
  },
  mounted() {
    this.getMonthFunc();
    this.init();
  },
  computed: {
    ...mapState(["sovPageSave","sites"]),
    expandFunc() {
      if (!this.expandAll) {
        return [];
      } else {
        return this.tableData.map((v) => {
          return v.id;
        });
      }
    },
    tableHeight() {
      return document.body.clientHeight - 350;
    },
  },
  methods: {
    ...mapMutations({
      setSovPageSave: "SET_SOVPAGESAVE",
    }),
    disabledDate(time) {
      if (this.timeRange) {
        if (this.timeRange.length > 1) {
          return (
            time.getTime() < new Date(this.timeRange[0]).getTime() ||
            time.getTime() >
              new Date(this.timeRange[this.timeRange.length - 1]).getTime()
          );
        } else {
          return true;
        }
      }
    },
    async downLoadRankingListReportFunc() {
      console.log(this.brandListId);
      downLoadSovBrandRankingListReport(
        {
          startMonth: this.duration[0],
          endMonth: this.duration[1],
          id: this.brandListId.join("|"),
        },
        `品牌月投放数据.xlsx`
      )
        .then(() => {
          this.$message({
            type: "success",
            message: "文件正在下载",
          });
        })
        .catch(() => {
          this.$message.error("文件下载失败,请联系管理员!");
        });
    },
    async lazyLoad() {
      let res = await getSovBrandAggMonth();
      console.log(res);
      if (res.code === 0) {
        let temp = [];
        res.data.forEach((v) => {
          temp.push({
            value: v,
            label: v,
          });
        });
        this.options = [
          {
            value: "zhiding",
            label: "指定月",
            children: temp,
          },
          {
            value: "1mon",
            label: "近1个月",
          },
          {
            value: "3mon",
            label: "近3个月",
          },
          {
            value: "6mon",
            label: "近6个月",
          },
          {
            value: "12mon",
            label: "近12个月",
          },
          {
            value: "thisYear",
            label: "今年至今",
          },
          {
            value: "lastYear",
            label: "去年全年",
          },
        ];
      }
    },
    async init() {
      // this.lazyLoad();
      await this.getBrandMonth();
      await this.getBrand();
      await this.getCategory();
      // await this.getBrandList();
    },
    async getMonthFunc() {
      let res = await getSovProductAggMonth();
      console.log(res);
      if (res.code === 0) {
        this.timeRange = res.data;
        storage.setItem("timeRange", res.data);
      }
    },
    async getBrandMonth() {
      let res = await getBrandMonth();
      console.log(res);
      if (res.code === 0) {
        this.time = res.data;
        this.tempTime = res.data;
        this.duration = [res.data, res.data];
      } else {
        this.$message.error("获取时间失败，请联系管理员!");
      }
    },
    async getBrand() {
      let res = await getBrand();
      if (res.code === 0) {
        this.chooseBrandList = this.formatBrandList(res.data);
      } else if (res.code !== 123) {
        this.$message.error("获取数据失败，请联系管理员!");
      }
      console.log(res);
    },
    async getCategory() {
      let res = await getCategory();
      if (res.code === 0) {
        res.data.forEach((v) => {
          this.categoryList.push({
            label: v.tagName,
            value: v.tagId,
          });
        });
        // this.tabType = this.categoryList[0].value;
      }
    },
    async getBrandList() {
      this.brandListTableLoading = true;
      let res = await getBrandList({
        brand: this.selBrand.join("|"),
        type: this.tabType,
        page: this.currentPage,
        size: this.size,
        platform:this.siteType,
        sortColumn: this.sortColumn,
        sortDirection: this.sortDirection,
        startMonth: this.duration[0],
        endMonth: this.duration[1],
      });
      if (res.code === 0) {
        console.log(res);
        if (this.brandList.length !== 0) {
          res.data.brandInfos = this.brandList.concat(res.data.brandInfos);
        }
        // res.data.brandInfos.forEach((v, i) => {
        //   v.index = ++i + this.size * (this.currentPage - 1);
        // });
        // 将数据中的所有null值换成'--'
        this.formatNull(res.data.brandInfos);

        // 去除重复项
        let tempRes = new Map();
        res.data.brandInfos = res.data.brandInfos.filter(
          (a) => !tempRes.has(a.id) && tempRes.set(a.id, 1)
        );
        console.log(res.data.brandInfos);
        this.tableData = res.data.brandInfos;

        console.log(this.tableData);
        this.dataTotal = res.data.total;
        this.brandListTableLoading = false;
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    formatBrandList(data) {
      let arr = [];
      data.forEach((v) => {
        arr.push({
          label: v.brand,
          value: v.brandId,
        });
      });
      return arr;
    },
    sortChange(row) {
      console.log(row);
      this.sortColumn = row.prop;
      this.sortDirection = row.order === "descending" ? "desc" : "asc";
      this.getBrandList();
    },

    expandAllFunc() {
      this.expandAll = !this.expandAll;
      this.isExpand = !this.isExpand;
    },
    brandCompare() {
      if (this.brandListId.length < 2) {
        this.$message.info({
          type: "info",
          message: "请至少选择两个品牌去对比",
        });
      } else if (this.brandListId.length > 5) {
        this.$message.info({
          type: "info",
          message: "最多选择5个品牌做对比",
        });
      } else {
        console.log(this.brandList)
        const query = {
          brands: this.btoa(this.brandListId.toString()),
          brandNameList: encodeURI(JSON.stringify(this.brandNameList)),
          duration: JSON.stringify(this.duration),
        };
        let array = []
        this.brandList.forEach(i => {
          array.push(i.logo)
        })
        storage.setItem("logo", array);
        this.$router.push({ name: "sovBrandCompare", query });
      }
    },
    downLoadbrand() {
      if (this.brandListId.length < 1) {
        this.$message.info({
          type: "info",
          message: "请至少选择1个品牌",
        });
      } else if (this.brandListId.length > 10) {
        this.$message.info({
          type: "info",
          message: "最多选择10个品牌下载",
        });
      } else {
        this.downLoadRankingListReportFunc();
      }
    },
    setValue(){
      if(this.selBrand.length === 1){
        return this.chooseBrandList.filter(item => item.value === this.selBrand[0])[0].label
      } else{
        return this.chooseBrandList.filter(item => item.value === this.selBrand[0])[0].label+ ',+'+(this.selBrand.length-1)
      }
    },
    selectFuncHandle(item) {
      const temp = [];
      this.brandList = item;
      item.forEach((v) => {
        temp.push({
          label: v.brand,
          value: v.id,
        });
      });
      this.brandNameList = temp;
      this.brandListId = item.map((v) => v.id);
    },
    toBrand(row) {
      // console.log(row);
      // const query = {
      //   brand: encodeURI(row.brand),
      //   brandId: this.btoa(row.id),
      //   duration: JSON.stringify(this.duration),
      // };
      // this.$router.push({ name: "sovBrand", query });
      storage.setItem("logo", row.logo);
      this.setSovPageSave(this.currentPage);
    },
    async nextPage(page) {
      this.currentPage = page;
      await this.getBrandList();
      this.brandList.forEach((row) => {
        this.$refs["multipleTable"].toggleRowSelection(row, true);
      });
    },
    newBrand() {
      this.dialogVisible = true;
    },
    disabledSelect(row) {
      if (this.brandListId.includes(row.id)) {
        return true;
      } else if (this.brandListId.length > 9) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    $route: {
      handler() {
        this.currentPage = this.sovPageSave;
      },
      immediate: true,
    },
    duration(val) {
      if (val[0] === "zhiding") {
        this.time = val[1];
      } else {
        this.time = this.tempTime;
      }
      this.brandList = [];
      this.getBrandList();
    },
    selBrand() {
      this.currentPage = 1;
      this.getBrandList();
    },
    tabType(val, oldVal) {
      if (oldVal !== "") {
        this.brandListId = [];
        this.brandList = [];
        this.currentPage = 1;
        this.getBrandList();
      }
    },
    siteType(val, oldVal) {
        this.getBrandList();
    },
  },
};
</script>

<style lang="scss">
.sovWrap {
  //.el-radio-button__original-radio:checked + .el-radio-button__inner,
  //.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  //  background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
  //  border-image: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
  //  border-color: transparent;
  //  box-shadow: -1px 0 0 0 transparent;
  //}

  .el-radio-button__inner {
    background-color: #f8f9fe;
  }

  .el-radio-button__inner:hover {
    color: #606266;
  }

  thead .el-table-column--selection .cell {
    display: none;
  }

  .el-table
    .el-table__header-wrapper
    .el-table__header
    thead
    > tr
    > th
    > .cell {
    color: #8c98a5;
    font-size: 14px;
  }

  //.el-checkbox__input.is-checked .el-checkbox__inner {
  //  background-color: #e09a29;
  //  border-color: #e09a29;
  //}
  //
  //.el-checkbox__inner.is-focus .el-checkbox__inner {
  //  border-color: #e09a29;
  //}
  //
  //.el-checkbox__inner:hover {
  //  border-color: #e09a29;
  //}

  .el-table__expanded-cell {
    background-color: #f9fcff;
    padding: 0 !important;
    padding-left: 140px !important;
  }

  //.el-pagination.is-background .el-pager li:not(.disabled).active {
  //  background: #e09a29;
  //  color: #fff !important;
  //}
  //
  //.el-pagination.is-background .el-pager li:not(.disabled):hover {
  //  color: #e09a29;
  //}

  .el-table td.el-table__cell {
    padding: 8px;
  }
  .filter .el-date-editor .el-range-separator {
    width: 9%;
  }
}
.date_block .el-range-editor.el-input__inner{
  border:0;
  box-shadow: none;
}
.date_block .el-date-editor .el-range__close-icon{
  display: none;
}
.date_block .el-date-editor .el-range-separator{
  width:auto;
}
</style>

<style scoped lang="scss">
.sovWrap {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background:#fff;
  .container {
    font-size: 16px;
    position: relative;
    .title_block{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height:72px;
      border-bottom:1px solid #EDEDED;
      padding:0 20px;
      p{
        font-weight: 500;
        font-size:22px;
        display: flex;
        align-items: center;
        gap:20px;
        .dataTime{
          color:#666;
          font-size:14px;
        }
      }
    }
    .search_block{
      padding:0 20px;
      display: flex;
      justify-content: flex-end;
      align-items:center;
      gap:20px;
      .date_block{
        display: flex;
        align-items: center;
        font-size:14px;
      }
      .line{
        border-left:1px solid #000;
        width:1px;
        height:12px;
      }
    }
    .sovTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .filter {
        flex: 1;
        display: flex;
        justify-content: flex-start;
      }

      .newBrand {
        height: 28px;
        line-height: 25px;
        padding: 0 9px;
        color: #fff;
        background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
        border-radius: 4px;

        .iClass {
          margin-left: 6px;
        }
      }
    }
  }

  .table {
    background-color: #fff;
    .tab {
      position: relative;
      height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .spanClass {
        margin-left: 6px;
        padding: 0;
        width: 61px;
        height: 28px;
        line-height: 28px;
        color: #fff;
        background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
        border-radius: 4px;
      }
      .downBtn {
        float: left;
      }

      .abClass {
        position: absolute;
        left: 14px;
        bottom: -38px;
        z-index: 900;
        color: #8c98a5;
      }
    }

    .tableMain {
      // margin-top: 16px;
      .expandClass {
        padding: 0;
        padding-left: 10px;
        color: #8c98a5;
        background: #f4f3f3;
        border: none;
        border-radius: 4px;
        transition: transform 0.2s ease-in-out;

        i {
          margin: 0 8px;
        }
      }

      //.isExpandClass {
      //  background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
      //  color: #fff;
      //}

      .paddingClass {
        padding: 8px 0;

        span {
          display: inline-block;
          text-align: center;
        }
      }

      .borderClass {
        border-bottom: 1px solid #e7e7e7;
      }

      .iStyle {
        position: absolute;
        color: #c0c4cc;
      }

      .iblue {
        color: #409eff;
      }

      .ibottom {
        top: 7px;
      }

      .itop {
        top: 0px;
      }
    }
  }

  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}

</style>
